import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Car, AvailableOptions, SelectedOptions, Option, Ids } from './models';
import { formatHTML } from './utils';
import { oneGraphClient } from '../contexts/app-context';
import BASE_QUERY from '../graphql/configured-car-by-carline-with-features.graphql';
import CHANGE_QUERY from '../graphql/change-configured-car-feature.graphql';
import {
  ConfiguredCarAve,
  ConfiguredCarByCarlineWithFeatures,
  ConfiguredCarFeatureGroup,
  ExteriorColorConfiguredCarFeatureFragment,
  ExteriorColorConfiguredCarFeatureImageFragment,
  FuelType,
  InteriorConfiguredCarFeatureImageFragment,
  InteriorConfiguredCarPackageFragment,
  RimsConfiguredCarFeatureFragment,
  RimsConfiguredCarFeatureImageFragment,
  TechnicalDataConsumptionAndEmissionSummaryFragment,
  VehicleType,
} from '../../../@types/onegraph';

export interface CarlineInput {
  brand: string;
  carlineId: string;
  country: string;
  language: string;
}

const isNotDefined = <T>(input: T | undefined | null): input is undefined | null => {
  if (input === undefined) return true;
  if (input === null) return true;

  return false;
};

function restructureRawData(
  rawData:
    | ExteriorColorConfiguredCarFeatureFragment
    | InteriorConfiguredCarPackageFragment
    | RimsConfiguredCarFeatureFragment,
): Option | undefined {
  if (!rawData || !rawData.images) return undefined;

  const { images, featureGroup } = rawData;

  const tile = (() => {
    if (featureGroup === ConfiguredCarFeatureGroup.ExteriorColors)
      return (images as ExteriorColorConfiguredCarFeatureImageFragment).outerTileSmallHD;
    if (featureGroup === ConfiguredCarFeatureGroup.Rims)
      return (images as RimsConfiguredCarFeatureImageFragment).wheelImageS;
    if (featureGroup === ConfiguredCarFeatureGroup.InteriorTiles)
      return (images as InteriorConfiguredCarFeatureImageFragment).interiorTileButton;
    return null;
  })();

  if (!tile) return undefined;

  return {
    id: rawData.pr3,
    isInConflict: rawData.status.inConflict,
    category: {
      id: rawData.family.id,
      name: rawData.family.name,
    },
    name: formatHTML(rawData.name),
    tile,
  };
}

export function createCar(
  ave: ConfiguredCarAve | null,
  carlineName: string,
  consumptionAndEmissionSummary: TechnicalDataConsumptionAndEmissionSummaryFragment,
  countryCode: string,
  id: Ids,
  prices: ConfiguredCarByCarlineWithFeatures['configuredCarByCarlineWithFeatures']['prices'],
  rawExteriorColors: ExteriorColorConfiguredCarFeatureFragment[],
  rawInteriors: InteriorConfiguredCarPackageFragment[],
  rawRims: RimsConfiguredCarFeatureFragment[],
  vehicleType: VehicleType,
  carlineId?: string,
): Car {
  const nonExclusiveExteriorColorsWithImage = rawExteriorColors.filter(
    (exteriorColor) =>
      exteriorColor?.images?.outerTileSmallHD && exteriorColor.family.id !== 'color-type:exclusive',
  );

  if (!nonExclusiveExteriorColorsWithImage.length)
    throw new Error('Incomplete Configuration: no exterior color images');

  // TODO
  // Due to something wrong with DPU there are rims slected:true && changeable:false now
  // If Team Guiter Hero finds the solution,
  // remove the additional filter rim.images.wheelImageS && rim.status.available && rim.status.selected
  const availableRimsWithImage = rawRims.filter(
    (rim) =>
      (rim?.images?.wheelImageS && rim.status.available && rim.status.changeable) ||
      (rim?.images?.wheelImageS && rim.status.available && rim.status.selected),
  );

  if (!availableRimsWithImage.length)
    throw new Error('Incomplete Configuration: no available + image rims');

  const interiorsWithImage = rawInteriors.filter((interior) => interior.images?.interiorTileButton);

  if (!interiorsWithImage.length) throw new Error('Incomplete Configuration: no interior images');

  const rawSelectedExteriorColor = nonExclusiveExteriorColorsWithImage.find(
    (exteriorColor) => exteriorColor.status.selected,
  );
  if (!rawSelectedExteriorColor)
    throw new Error('Incomplete Configuration missing: selected exterior');

  const rawSelectedRim = availableRimsWithImage.find((rim) => rim.status.selected);
  if (!rawSelectedRim) throw new Error('Incomplete Configuration missing: selected rim');

  const rawSelectedInterior = interiorsWithImage.find((interior) => interior.status.selected);
  if (!rawSelectedInterior) throw new Error('Incomplete Configuration missing: selected interior');

  const selectedOptions: SelectedOptions = {
    exteriorColor: restructureRawData(rawSelectedExteriorColor),
    rim: restructureRawData(rawSelectedRim),
    interior: restructureRawData(rawSelectedInterior),
  };

  const availableOptions: AvailableOptions = {
    exteriorColor: nonExclusiveExteriorColorsWithImage
      .map(restructureRawData)
      .filter(Boolean) as Option[],
    rim: availableRimsWithImage.map(restructureRawData).filter(Boolean) as Option[],
    interior: interiorsWithImage.map(restructureRawData).filter(Boolean) as Option[],
  };

  const {
    powerConsumption,
    emissionSummary,
    classFb,
    consumption,
    fuelConsumptionBatteryDischarged,
    classBatteryDischarged,
  } = extractConsumptionAndEmissionValues(consumptionAndEmissionSummary, vehicleType);

  // prices.rotr is the sum of all possible costs when buying the car. It is legally required for Nl market.
  const price = countryCode === 'nl' ? prices?.rotr?.formattedValue : prices?.total?.formattedValue;
  if (isNotDefined(price)) throw Error('no price');

  // @todo: check whether ave string is available in all three Visualizer modes (Asset API)
  const aveRenderString = ave?.renderString;
  if (isNotDefined(aveRenderString)) throw Error('no ave string');

  return {
    availableOptions,
    ave: aveRenderString,
    carlineId,
    powerConsumption: powerConsumption || '',
    emission: emissionSummary || '',
    classFb,
    consumption: consumption || '',
    fuelConsumptionBatteryDischarged,
    classBatteryDischarged,
    id,
    carlineName,
    price,
    selectedOptions,
    vehicleType,
  };
}

export const fetchCar = async (
  localeService: GfaLocaleServiceV1,
  carlineId: string,
  features: string[],
) => {
  const country = localeService.countryCode.toLowerCase();
  const language = localeService.language.toLowerCase();

  const carlineInput: CarlineInput = {
    brand: 'A',
    carlineId,
    country,
    language,
  };

  const {
    data: { configuredCarByCarlineWithFeatures },
  } = await oneGraphClient.query({
    query: BASE_QUERY,
    variables: {
      carlineInput,
      features,
    },
  });

  const { id, carline, media, prices, technicalData, catalog, carlineStructureCarline } =
    configuredCarByCarlineWithFeatures as ConfiguredCarByCarlineWithFeatures['configuredCarByCarlineWithFeatures'];

  // @todo: validate if the following are required
  if (!media?.ave) throw new Error('missing ave string');
  const { ave } = media;

  if (!carline) throw new Error('missing Carline');
  const carlineName = carline.name;

  if (!technicalData?.consumptionAndEmission?.summary) throw new Error('missing Technical Data');
  const consumptionAndEmissionSummary = technicalData.consumptionAndEmission.summary;

  if (!catalog?.features.exteriorColors) throw new Error('missing Car Parts: Exterior colors');
  const { exteriorColors } = catalog.features;

  if (!catalog?.features.rims) throw new Error('missing Car Parts: Rims');
  const { rims } = catalog.features;

  if (!catalog?.packagesDetails.interiors) throw new Error('missing Car Parts: Interiors');
  const { interiors } = catalog.packagesDetails;

  if (!carlineStructureCarline?.vehicleType) throw new Error('missing Car Parts: Vehicle Type');

  return createCar(
    ave,
    carlineName,
    consumptionAndEmissionSummary,
    country,
    id,
    prices,
    exteriorColors,
    interiors,
    rims,
    carlineStructureCarline.vehicleType,
  );
};

export const changeConfiguredCar = async (
  localeService: GfaLocaleServiceV1,
  feature: string,
  car: Car,
) => {
  const country = localeService.countryCode.toLowerCase();
  const language = localeService.language.toLowerCase();

  const { id: carIds } = car;
  if (!carIds) return undefined;

  const {
    equipmentOptions,
    exteriorColor,
    interiorColor,
    model: { year, code, version, extensions },
  } = carIds;

  const changeConfiguredCarFeatureInput = {
    action: 'ADD',
    autoResolveConflicts: true,
    feature,
    id: {
      brand: 'A',
      country,
      equipmentOptions,
      language,
      model: {
        code,
        extensions: extensions?.length ? extensions : null,
        version,
        year,
      },
      exteriorColor,
      interiorColor,
    },
  };

  const {
    data: {
      changeConfiguredCarFeature: { configuredCar },
    },
  } = await oneGraphClient.query({
    query: CHANGE_QUERY,
    variables: {
      changeConfiguredCarFeatureInput,
    },
  });

  const { id, carline, media, prices, technicalData, catalog, carlineStructureCarline } =
    configuredCar as ConfiguredCarByCarlineWithFeatures['configuredCarByCarlineWithFeatures'];

  // @todo: validate if the following are required
  if (!media?.ave) throw new Error('missing ave string');
  const { ave } = media;

  if (!carline) throw new Error('missing Carline');
  const carlineName = carline.name;

  if (!technicalData?.consumptionAndEmission?.summary) throw new Error('missing Technical Data');
  const consumptionAndEmissionSummary = technicalData.consumptionAndEmission.summary;

  if (!catalog?.features.exteriorColors) throw new Error('missing Car Parts: Exterior colors');
  const { exteriorColors } = catalog.features;

  if (!catalog?.features.rims) throw new Error('missing Car Parts: Rims');
  const { rims } = catalog.features;

  if (!catalog?.packagesDetails.interiors) throw new Error('missing Car Parts: Interiors');
  const { interiors } = catalog.packagesDetails;

  const changedId: Ids = {
    ...carIds,
    interiorColor: id.interiorColor,
    exteriorColor: id.exteriorColor,
    equipmentOptions: id.equipmentOptions,
    model: {
      ...carIds.model,
    },
  };

  return createCar(
    ave,
    carlineName,
    consumptionAndEmissionSummary,
    country,
    changedId,
    prices,
    exteriorColors,
    interiors,
    rims,
    carlineStructureCarline?.vehicleType!,
  );
};

function extractConsumptionAndEmissionValues(
  consumptionAndEmissionSummary: TechnicalDataConsumptionAndEmissionSummaryFragment,
  vehicleType: VehicleType,
) {
  let powerConsumption;
  let consumption;
  let emissionSummary;
  let fuelConsumptionBatteryDischarged = '';
  let classBatteryDischarged = '';

  const { eecsSummary, consumptionsSummary, emissionsCO2Summary } = consumptionAndEmissionSummary;
  if (isNotDefined(consumptionsSummary)) throw new Error('no consumptionSummary');
  if (isNotDefined(emissionsCO2Summary)) throw new Error('no emissionSummary');

  if (vehicleType === VehicleType.Bev) {
    const electricCons = consumptionsSummary?.find((con) => con.fuelType === FuelType.Electrical);
    powerConsumption = electricCons?.values?.[0]?.formattedValue;
  } else if (vehicleType === VehicleType.Icev) {
    const fuelCons = consumptionsSummary?.find(
      ({ fuelType }) => fuelType && isICEfuelType(fuelType),
    );
    consumption = fuelCons?.values?.[0]?.formattedValue || '';
  } else {
    const electricCons = consumptionsSummary?.find((con) => con.fuelType === FuelType.Electrical);
    powerConsumption = electricCons?.values?.[0]?.formattedValue || '';

    emissionSummary = emissionsCO2Summary?.find((con) => con.values !== null)?.values?.[0]
      ?.formattedValue;

    const fuelCons = consumptionsSummary?.find(
      ({ fuelType }) => fuelType && isICEfuelType(fuelType),
    );
    fuelConsumptionBatteryDischarged = fuelCons?.values?.[0]?.formattedValue || '';

    consumption = fuelCons?.values?.[1]?.formattedValue || '';
    classBatteryDischarged = eecsSummary?.[1]?.formattedValue || '';
  }

  const classFb = eecsSummary?.[0]?.formattedValue || '';
  if (!emissionSummary) {
    emissionSummary = emissionsCO2Summary?.[0]?.values?.[0]?.formattedValue;
  }

  return {
    powerConsumption,
    emissionSummary,
    classFb,
    consumption,
    fuelConsumptionBatteryDischarged,
    classBatteryDischarged,
  };
}

function isICEfuelType(fuelType: FuelType) {
  return Boolean(
    fuelType === FuelType.Petrol || fuelType === FuelType.Diesel || fuelType === FuelType.Cng,
  );
}
export function basePrNumbersToItems(basePrNumbers?: string) {
  return basePrNumbers ? basePrNumbers.split(',') : [];
}
